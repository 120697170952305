import Head from 'next/head'
import * as Sentry from '@sentry/node'
import '../components/globals.scss'

if (process.env.NEXT_PUBLIC_SENTRY_DSN != null) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.ENV_NAME != null ? process.env.ENV_NAME : 'local',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  })
}

function App ({ Component, pageProps, err }): JSX.Element {
  return <>
    <Head>
      <title>Effective long-form slow-paced communication on the internet - Letters</title>
      <link rel="icon" type="image/svg+xml" href="favicon.svg" />
      <link href="https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;700&display=swap" rel="stylesheet" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#0F0E17" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#0F0E17" />
      <meta name="viewport" content="width=device-width, initial-scale = 1.0, maximummain-light-scale=1.0, user-scalable=no" />
      <meta httpEquiv="Content-Language" content="en" />
      <meta name="description" content="Have meaningful conversations with groups of people privately or in public using your email only." />
      {/** <script async defer data-domain="lettergroups.com" src="https://plausible.io/js/plausible.js" /> */ }
    </Head>
    <Component {...pageProps} err={err} />
  </>
}

export default App
